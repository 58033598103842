<template>
  <div class="container" id="evaluate">
    <div class="content">
      <div class="title">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        企业绩效评价行业值
      </div>
      <div class="search_box">
        <span class="lable">行业：</span>
        <div style="width: 200px">
          <selectTree ref="selectTree" isSw="database" isName="database" />
        </div>

        <span style="margin-left: 6px" class="lable">企业范围：</span>
        <el-select
          @change="search"
          @focus="radioClose()"
          v-model="formData.industry_range"
          style="width: 200px"
          size="medium"
        >
          <el-option value="0" label="全行业"></el-option>
          <el-option
            v-if="industry == 'tj'"
            value="1"
            label="大型企业"
          ></el-option>
          <el-option
            v-if="industry == 'tj'"
            value="2"
            label="中型企业"
          ></el-option>
          <el-option
            v-if="industry == 'tj'"
            value="3"
            label="小型企业"
          ></el-option>
        </el-select>
        <!-- 添加地区行业值 -->
        <span style="margin-left: 20px" class="lable">地区： </span>
        <el-select
          size="medium"
          style="width: 200px"
          v-model="regions"
          @change="search"
        >
          <el-option
            v-for="text in regions_arr"
            :label="text"
            :value="text"
            :key="text"
          >
          </el-option>
        </el-select>
        <span style="margin-left: 20px" class="lable">年份：</span>
        <el-select
          @change="change"
          size="medium"
          v-model="formData.year"
          style="width: 200px"
          @focus="radioClose()"
        >
          <el-option value="2021" label="2021"></el-option>
          <el-option value="2020" label="2020"></el-option>
          <el-option value="2019" label="2019"></el-option>
          <el-option value="2018" label="2018"></el-option>
          <el-option value="2017" label="2017"></el-option>
          <el-option value="2016" label="2016"></el-option>
        </el-select>
        <span style="margin-left: 20px" class="lable">年度：</span>
        <el-select
          @change="search"
          size="medium"
          v-model="formData.period"
          style="width: 200px"
          @focus="radioClose()"
        >
          <el-option value="00" label="年度"></el-option>
          <el-option
            v-if="industry == 'sw'"
            value="01"
            label="三季度"
          ></el-option>
          <el-option
            v-if="industry == 'sw'"
            value="02"
            label="二季度"
          ></el-option>
          <el-option
            v-if="industry == 'sw'"
            value="03"
            label="一季度"
          ></el-option>
        </el-select>
        <div class="lable-button">
          <el-button
            icon="el-icon-search"
            style="height: 35px; font-size: 14px"
            class="search"
            @click="fetchData(1)"
            >查询</el-button
          >
          <el-button
            icon="el-icon-refresh-right"
            style="height: 35px; font-size: 14px"
            class="reset"
            @click="resetData()"
            >重置</el-button
          >
        </div>
      </div>
      <div class="table_box">
        <fivePower :data="datas"></fivePower>
      </div>
    </div>
  </div>
</template>
<script>
import { indicators, indicators_values } from "@/api/database.js";
export default {
  components: {
    selectTree: require("@/components/selectTree/selectTree.vue").default,
    fivePower: require("@/components/database/fivePower.vue").default,
  },
  data() {
    return {
      industry: "tj",
      formData: {
        industry_range: "0",
        period: "00",
        year: "2020",
        industry_code: "",
      },
      datas: {},
      regions: "全国", //地区值，默认全国
      regions_arr: [], //地区列表，
    };
  },
  created() {
    this.setData();
  },

  methods: {
    async setData() {
      const { data } = await indicators();
      this.regions_arr = data.regions;
      this.fetchData(1);
    },
    change() {
      // 当用户选择年度非2018-2020时，不提供地区行业值选择，仅提供“全国”。
      if (this.formData.year > 2020 || this.formData.year < 2018) {
        this.regions = "全国";
        this.regions_arr = ["全国"];
      } else {
        indicators().then(({ data }) => {
          this.regions_arr = data.regions;
        });
      }
    },
    resetData() {
      this.industry = "tj";
      this.formData = {
        industry_range: "0",
        period: "00",
        year: "2020",
        industry_code: "",
      };
      this.regions = "全国";
      indicators_values({ ...this.formData, industry_code: "tj01" }).then(
        ({ data }) => {
          this.datas = data;
        }
      );
    },
    radioClose() {
      this.$refs.popover.doClose();
    },
    getStatus() {
      return this.$refs.popover && this.$refs.popover.showPopper;
    },
    fetchData() {
      const formData = this.formData;
      const selectTree = this.$refs[`selectTree${this.industry}`];
      const industry = selectTree ? selectTree.get_industry_code() : "tj01";
      if (!industry) return setTimeout(this.fetchData.bind(this), 200);
      indicators_values({
        ...formData,
        region: this.regions,
        industry_code: industry,
      }).then(({ data }) => {
        this.datas = data;
      });
    },
    search() {
      // this.fetchData();
    },
  },
  watch: {
    industry() {
      if (this.industry == "sw") {
        this.formData.industry_range = "0";
      } else {
        this.formData.period = "00";
      }
      // this.fetchData();
    },
  },
};
</script>
<style lang="less" scoped>
#evaluate {
  min-height: 800px;
  background: #fff;
  .content {
    border-radius: 8px;
    overflow: hidden;
    min-height: 400px;
    .title {
      padding: 20px;
      font-size: 18px;
      font-weight: 550;
      background: #fff;
      border-bottom: 1px solid rgb(240, 237, 237);
    }
  }
  .search_box {
    position: relative;
    background: #fff;
    padding: 20px 30px 10px 30px;
    display: flex;
    align-content: center;
    > .lable {
      color: #515a6e;
      line-height: 31px;
    }
    > .lable-button {
      position: absolute;
      top: 20px;
      right: 40px;
      button {
        cursor: pointer;
        border-radius: 3px;
        padding: 4px 22px;
        font-size: 12px;
        border: 1px solid #1989fa;
      }
      .reset {
        background: #ffffff;
        color: #1989fa;
        margin-right: 5px;
      }
      .search {
        background: #1989fa;
        color: #fff;
      }
    }
  }
  .input {
    height: 32px;
    line-height: 32px;
    border: 1px solid #d6dde4;
    color: #666;
    border-radius: 4px;
    font-size: 13px;
    padding-left: 20px;
    cursor: pointer;
    padding-right: 20px;
  }
  .table_box {
    background: #fff;
    padding: 10px 30px 10px 30px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1840px;
  }
}
</style>
