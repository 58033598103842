<template>
  <div class="five_power">
    <div class="kindBox">
      <div
        style="font-size:14px;"
        v-for="kind in type"
        :key="kind"
        :class="kindof == kind ? 'active' : ''"
        @click="kindof = kind"
      >
        {{ kind }}
      </div>
    </div>
    <el-table stripe :header-cell-style="{ background: '#e9f3ff', color: '#333', }" style="margin-top: 10px;font-size:16px;color:#333" :data="table_data">
      <el-table-column prop="name" label="项目"></el-table-column>
      <el-table-column prop="excel_val" label="优秀值"></el-table-column>
      <el-table-column prop="avg_val" label="平均值"></el-table-column>
      <el-table-column prop="poor_val" label="较差值"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
  },

  computed: {
    type() {
      return ["盈利能力", "长期偿债能力", "短期偿债能力", "营运能力", "发展能力"];
    },
    table_data() {
      var arr = [];
      const data = this.data[this.kindof];
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const item = data[key];
          arr.push({ ...item, name: key });
        }
      }
      return arr;
    },
  },
  data() {
    return {
      kindof: "盈利能力",
    };
  },
};
</script>
<style lang="less" scoped>
.five_power {
  .kindBox {
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    div {
      padding: 9px 12px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      font-size: 12px;
      cursor: pointer;
    }
    div:nth-last-child(1) {
      border-right: 1px solid #ccc;
    }
    .active {
    background: #1270ff;
    border: 1px solid #1270ff;
    opacity: 1;
    // border-radius: 4px 0px 0px 4px;
    color: #fff;
  }
  }
}
</style>
